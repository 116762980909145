ddd.factory('tokenInterceptor', function($injector, $q) {

    function replayRequest(config, deferredPromise) {
        var $http = $injector.get('$http');
        $http(config).then(function(response) {
            deferredPromise.resolve(response);
        }, function(replayRequestError) {
            deferredPromise.reject(replayRequestError);
        });
    }

    return {
        request: function(config) {
            // only inject auth headers on requests sent to our own API
            if (!config.apiRequest) {
                return config;
            }

            var tokenService = $injector.get('tokenService');
            config.headers = config.headers || {};
            var token = tokenService.getToken();
            if (token) {
                config.headers.Authorization = 'Bearer ' + token;
            }

            return config;
        },
        responseError: function(rejection) {
            var tokenService = $injector.get('tokenService');
            if (rejection.status !== 401 && rejection.status !== 403) {
                return $q.reject(rejection);
            }

            if (rejection.config.url === tokenService.getRequestTokenUrl()) {
                return $q.reject(rejection);
            }

            var deferred = $q.defer();
            tokenService.registerTokenRequestedCallbacks(function() {
                replayRequest(rejection.config, deferred);
            });
            if (!tokenService.requestingToken) {
                tokenService.refreshToken().then(null, function(refreshError) {
                    deferred.reject(rejection);
                });
            }

            return deferred.promise;
        }
    };
})

.config(function($httpProvider) {
    $httpProvider.interceptors.push('tokenInterceptor');
});
