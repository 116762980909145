ddd.controller('HeaderCtrl', function($scope, $log, $state, Aside, userService, fullscreenMode) {
    'use strict';

    var menuChildScope = $scope.$new();

    var menuAside = new Aside(menuChildScope, { templateUrl: 'views/shared/aside-functions'});

    $scope.goToPortal = function() {
        $window.location = 'DDD_PORTAL_URL_PLACEHOLDER';
    };

    $scope.$on('showLeftAside', function() {
        $scope.selectedAside = 'showLeftAside';

        // Hide active instances
        menuAside.isShown() ? menuAside.hide() : menuAside.show();
    });

    /**
     *  Fullscreen toggle
     */
    $scope.toggleFullscreenMode = function() {
        fullscreenMode.toggle();
    };

    $scope.isFullscreen = false; // Fullscreen will always be false by default

    $scope.$watch(function(){
        return fullscreenMode.isEnabled()
    }, function(isEnabled) {
        $scope.isFullscreen = isEnabled;
    });

    $scope.$on('device:offline', function(event, device) {
        if (device.isApi)
            $scope.apiOffline = true;
    });

    $scope.$on('device:retry:success', function(event, device) {
        if (device.isApi) {
            $scope.apiOffline = false;
            $scope.apiConnecting = false;
        }
    });

    $scope.$on('device:retry:start', function(event, device) {
        if (device.isApi)
            $scope.apiConnecting = true;
    });

    $scope.$on('device:retry:failed', function(event, device) {
        if (device.isApi)
            $scope.apiConnecting = false;
    });

    $scope.hideAside = function() {
        $timeout(function() {
            menuAside.hide();
        }, 200);
    };

    $scope.logout = function() {
        userService.logout();
        menuAside.hide();
    };
});
