ddd.factory('inputService', function($rootScope, $document, $sniffer, $state, $timeout) {
    var inputService = {};
    var doc = angular.element($document);
    var input;
    var onEnterFunc;
    var inputDelay;
    var inputBuffer = '';

    var inputPressedHandler = function(keyEvent) {
        var capturedInput = String.fromCharCode(keyEvent.charCode);
        if (!/[a-z0-9%.+-æøå]/i.test(capturedInput)) return;

        inputBuffer += capturedInput;

        cancelBufferToInputPromise();
        inputDelay = $timeout(shiftBufferToInput, 50);
    };

    function cancelBufferToInputPromise() {
        if (inputDelay)
            $timeout.cancel(inputDelay);
    }

    function cancelAndShiftToInput() {
        cancelBufferToInputPromise();
        shiftBufferToInput();
    }

    function shiftBufferToInput() {
        var inputString = inputBuffer;
        inputBuffer = '';
        inputService.insertString(inputString);
    }

    var inputDownHandler = function(keyEvent) {
        switch (keyEvent.keyCode) {
            case 8: // Backspace
                cancelAndShiftToInput();
                keyEvent.preventDefault();
                inputService.doDelete(null, true);
                break;
            case 46: // Del
                cancelAndShiftToInput();
                inputService.doDelete();
                break;
            case 13: // Enter
                cancelAndShiftToInput();
                var data = {};
                data.buttonName = input.val();
                data.stateName = $state.$current.name;
                data.clientTime = new Date();
                data.posVersion = version;
                if (!$rootScope.$$phase)
                    return $rootScope.$apply(onEnterFunc);
                onEnterFunc();
                break;
            case 27: // Escape
                cancelBufferToInputPromise();
                input.val('');
                input.trigger('change');
                break;
        }
    };

    inputService.doDelete = function(inputElement, isBackspace) {
        var inputEle = inputElement || input;
        inputService.getSelection(inputEle, del);

        function del(selectionStart, selectionEnd) {
            var head = inputEle.val().substr(0, selectionStart);
            var tail = inputEle.val().substr(selectionEnd);
            if (selectionStart === selectionEnd) {
                if (isBackspace) head = head.substr(0, head.length - 1);
                else tail = tail.substr(1);
            }
            var newInput = head + tail;
            inputEle.val(newInput);
            inputEle.trigger('change');
        }
    };

    inputService.insertString = function(string, inputElement) {
        var inputEle = inputElement || input;
        inputService.getSelection(inputEle, inserter);

        function inserter(selectionStart, selectionEnd) {
            var preSelect = inputEle.val().substr(0, selectionStart);
            var postSelect = inputEle.val().substr(selectionEnd, inputEle.val().length);
            var newInput = preSelect + string + postSelect;
            inputEle.val(newInput);
            inputEle.trigger('change');
            var selection = selectionStart + string.length;
            inputService.select(selection, selection, inputEle);
        }
    };

    inputService.enableGlobalCapture = function(inputElement, onEnter) {
        if (inputElement.context.nodeName.toUpperCase() !== 'INPUT') return;
        // disable the previous input capture if one was active
        inputService.disableGlobalCapture();

        // update the current input to the one passed here
        if (inputElement)
            input = inputElement;
        // update the on enter func to match the one with the input
        if (onEnter)
            onEnterFunc = onEnter;

        doc.on('keypress', inputPressedHandler);
        doc.on('keydown', inputDownHandler);
    };

    inputService.disableGlobalCapture = function(inputElement) {
        // disable only if the input element matches the current one
        // or if disable is called without arguments
        if (inputElement !== input && inputElement) return;
        doc.off('keypress', inputPressedHandler);
        doc.off('keydown', inputDownHandler);
    };

    /**
     * Get the start and end index of the current selection for an input element
     *
     * Chrome does not allow selection on number inputs so we try out different ways
     * if we have to change type to allow selection, we have to use $timeout and
     * allow the rest of the function to continue inside that timeout.
     *
     * slectionEnd wont be correct on the FIRST run unless we use the timeout
     * BUT! Only if the input is populated by any other source than the keypad
     * @param  {DOMElement} input           The input element to get selection on
     * @param  {function}   continueFunc    Callback which takes start and end
     *                                      index as parameters
     * @return {object}                     Has the selectionStart and selectionEnd properties
     */
    inputService.getSelection = function(input, continueFunc) {
        var selectionStart, selectionEnd;
        try {
            selectionStart = input[0].selectionStart;
            selectionEnd = input[0].selectionEnd;
            if (continueFunc)
                continueFunc(selectionStart, selectionEnd);
        } catch (e) {
            var type = input.attr('type');
            input.attr('type', 'text');
            $timeout(function() {
                selectionStart = input[0].selectionStart;
                selectionEnd = input[0].selectionEnd;
                input.attr('type', type);
                if (continueFunc)
                    continueFunc(selectionStart, selectionEnd);
            }, 0);
        }
        return {
            selectionStart: selectionStart,
            selectionEnd: selectionEnd
        };
    };

    /**
     * Select range of text in an input element
     * @param  {Number}          start                zero-based index of start position of selection
     * @param  {Number}          end                  zero-based index of end position of selection
     * @param  {DOMElement|null} optionalUnboundInput An unbound input to use instead of the registered one
     */
    inputService.select = function(start, end, optionalUnboundInput) {
        var input = optionalUnboundInput || input;
        if (input) {
            $timeout(function() {
                setSelection(input, start, end);
            }, 0);
        }
    };

    /**
     * Select all the text in an input element
     * @param  {DOMElement|null} optionalUnboundInput An unbound input to use instead of the registered one
     */
    inputService.selectAll = function(optionalUnboundInput) {
        var input = optionalUnboundInput || input;
        if (input) {
            $timeout(function() {
                setSelection(input, 0, input[0].value.length);
            }, 0);
        }
    };

    /**
     * Private helper method used by select and selectAll
     * @param {DOMElement}  input    Input element to select on
     * @param {Number}      start    Zero-based start index
     * @param {Number}      end      Zero-based end index
     */
    function setSelection(input, start, end) {
        try {
            input[0].setSelectionRange(start, end);
        } catch (e) {
            var type = input.attr('type');
            input.attr('type', 'text');
            input[0].setSelectionRange(start, end);
            input.attr('type', type);
        }
    }

    return inputService;
});
