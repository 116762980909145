ddd.provider('tokenService', function() {
    'use strict';
    this.tokenStore = 'portalApiToken';
    this.refreshTokenStore = 'portalRefreshToken';
    this.tokenAuthClientId = "dddportal_clientid";
    var unauthorizedCallbacks = [];

    this.registerUnauthorizedTokenRequestCallback = function(callback) {
        unauthorizedCallbacks.push(callback);
    };

    this.$get = function(tokenRepository, $q, $cookies) {
        var tokenService = {};
        var provider = this;
        tokenService.tokenRequestCallbacks = [];

        tokenService.requestRefreshToken = function(username, password) {
            var deferred = $q.defer();
            setRequesting(deferred);
            tokenRepository.authorize(username, password, provider.tokenAuthClientId)
                .success(function(response, status) {
                    tokenService.setRefreshToken(response.refresh_token);
                    requestCallback(response, status, deferred);
                })
                .error(function(error) {
                    requestError(error, deferred);
                });

            return deferred.promise;
        };

        tokenService.refreshAuthToken = function(refreshToken) {
            var deferred = $q.defer();
            setRequesting(deferred);
            tokenRepository.refreshAuthToken(refreshToken, provider.tokenAuthClientId)
                .success(function(response, status) {
                    requestCallback(response, status, deferred);
                })
                .error(function(error) {
                    requestError(error, deferred);
                });

            return deferred.promise;
        };

        function setRequesting(deferred) {
            tokenService.requestingToken = true;
            deferred.promise.finally(function() {
                tokenService.requestingToken = false;
            });
        }

        function requestCallback(response, status, deferred) {
            var token = response.token || response.access_token;
            if (token) {
                tokenService.setToken(token);
                deferred.resolve(response);
                while (tokenService.tokenRequestCallbacks.length > 0) {
                    tokenService.tokenRequestCallbacks.shift()();
                }
            } else deferred.reject("No token available in data");
        }

        function requestError(error, deferred) {
            tokenService.setToken(null);
            deferred.reject(error);
            doUnauthorizedCallbacks();
        }

        function doUnauthorizedCallbacks() {
            tokenService.tokenRequestCallbacks = [];
            for (var i = 0; i < unauthorizedCallbacks.length; i++) {
                unauthorizedCallbacks[i]();
            }
        }

        tokenService.setupToken = function(credentials) {
            var client_id = credentials.client_id || this.getTokenClientId();
            this.setTokenClientId(client_id);
            if (!this.getRefreshToken())
                return this.requestRefreshToken(credentials.shopid, "to-be-portal-token", client_id);
            else
                return this.refreshAuthToken(this.getRefreshToken(), this.getTokenClientId());
        };

        tokenService.refreshToken = function() {
            if (!this.getRefreshToken()) {
                doUnauthorizedCallbacks();
                return $q.reject('No refresh token or client_id available');
            }
            return this.refreshAuthToken(this.getRefreshToken(), this.getTokenClientId());
        };

        tokenService.getRequestTokenUrl = function() {
            return tokenRepository.tokenRequestUrl;
        };

        tokenService.registerTokenRequestedCallbacks = function(callback) {
            this.tokenRequestCallbacks.push(callback);
        };

        tokenService.registerUnauthorizedTokenRequestCallback = function(callback) {
            provider.registerUnauthorizedTokenRequestCallback(callback);
        };

        tokenService.getUnauthorizedTokenRequestCallbacks = function() {
            return unauthorizedCallbacks;
        };

        tokenService.getToken = function() {
            return $cookies.get(provider.tokenStore);
        };

        tokenService.setToken = function(token) {
            if (!token)
                return $cookies.remove(provider.tokenStore);
            $cookies.put(provider.tokenStore, token);
        };

        tokenService.getRefreshToken = function() {
            return $cookies.get(provider.refreshTokenStore);
        };

        tokenService.setRefreshToken = function(token) {
            if (!token)
                return $cookies.remove(provider.refreshTokenStore);
            $cookies.put(provider.refreshTokenStore, token);
        };

        tokenService.getTokenClientId = function() {
            return $cookies.get('client_id');
        };

        tokenService.setTokenClientId = function(clientid) {
            return $cookies.put('client_id', clientid);
        };
        return tokenService;
    };
});
