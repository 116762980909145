ddd.provider('notificationService', function notificationService() {
    var timeout = 0;

    this.setMessageTimeout = function(value) {
        timeout = value;
    };

    this.getMessageTimeout = function() {
        return timeout;
    };

    this.$get = function(toaster) {
        var that = this;
        return {
            showJsonMessage: function(jsonMessage) {
                if (jsonMessage) {
                    var message = JSON.parse(jsonMessage);
                    toaster.pop(message.type, message.title, message.text, { timeOut: that.getMessageTimeout() });
                }
            }
        };
    };
});
