window.ddd = angular.module('portal', [
    'ui.router',
    'ngCookies',
    'pascalprecht.translate',
    'tmh.dynamicLocale',
    'angular-loading-bar',
    'mgcrea.ngStrap',
    'toaster'
]).

run(function($rootScope, $state, $stateParams, tokenService, $interval) {
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;

    tokenService.registerUnauthorizedTokenRequestCallback(function() {
        $state.go('login');
    });

    // Functions aside
    $rootScope.showLeftAside = function() {
        $rootScope.$broadcast('showLeftAside');
    };

    // Date/time indicator
    $rootScope.currentDate = new Date();
    var updateTime = $interval(function() {
        $rootScope.currentDate = new Date();
    }, 1000, 0); // every second

    var noAuthStates = ['login'];
    $rootScope.$on('$stateChangeStart',
        function(event, toState, toParams, fromState, fromParams) {
            if (!tokenService.getRefreshToken() && noAuthStates.indexOf(toState.name) === -1) {
                event.preventDefault();
                $rootScope.$state.go('login', {message: toParams.message});
            }
        });
})

.config(function($stateProvider, $urlRouterProvider, $locationProvider, apiProvider, $cookiesProvider, notificationServiceProvider) {
    apiProvider.setUrl('DDD_API_URL_PLACEHOLDER');
    notificationServiceProvider.setMessageTimeout(20000); // 20 secs

    var parts = location.hostname.split('.');
    var sndleveldomain = parts.slice(-2).join('.');
    $cookiesProvider.defaults.domain = sndleveldomain !== 'localhost' ? sndleveldomain : '';
    //console.log($cookiesProvider.defaults);

    $urlRouterProvider
        .otherwise('/');

    $stateProvider
        .state('root', {
            abstract: true,
            resolve: {
                token: function($q, tokenService) {
                    var deferred = $q.defer();
                    tokenService.refreshToken().finally(deferred.resolve);
                    return deferred.promise;
                }
            },
            views: {
                header: {
                    templateUrl: 'views/shared/header',
                    controller: 'HeaderCtrl'
                }
            }
        })
        .state('root.dashboard', {
            url: '/?message',
            views: {
                'content@': {
                    templateUrl: 'views/dashboard/dashboard',
                    controller: 'DashboardCtrl'
                }
            }
        })
        .state('login', {
            url: '/login',
            params: {
                message: null
            },
            views: {
                'content@': {
                    templateUrl: 'views/auth/login',
                    controller: 'LoginCtrl'
                }
            }
        });
})

.config(function($asideProvider) {
    angular.extend($asideProvider.defaults, {
        container: 'body',
        html: true
    });
})

// region 3rd party module configuration, i18n, l10n, angular-snap
.config(function($translateProvider, tmhDynamicLocaleProvider) {
    'use strict';
    tmhDynamicLocaleProvider.localeLocationPattern('assets/language/l10n/angular-locale_{{locale}}.js');
    $translateProvider.useStaticFilesLoader({
        prefix: 'assets/language/i18n/json/',
        suffix: '.json'
    });
    $translateProvider.useSanitizeValueStrategy('escaped');
    $translateProvider.fallbackLanguage('en-gb');
    $translateProvider.preferredLanguage('da-dk');
});

// Prototype extensions
if (typeof String.prototype.startsWith != 'function') {
    String.prototype.startsWith = function(str) {
        return this.slice(0, str.length) == str;
    };
}

if (typeof String.prototype.endsWith != 'function') {
    String.prototype.endsWith = function(str) {
        return this.slice(-str.length) == str;
    };
}
