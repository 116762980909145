ddd.factory('tokenRepository', function(api) {
    var header = {
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    var apiTokenRequestUrl = '/token';

    var tokens = {
        tokenRequestUrl: api.getUrl() + apiTokenRequestUrl,
        authorize: function(username, password, clientid) {
            return api({
                method: 'POST',
                url: apiTokenRequestUrl,
                data: "grant_type=password&username=" + username + "&password=" + password + "&client_id=" + clientid,
                headers: header,
            });
        },
        refreshAuthToken: function(refreshToken, clientid) {
            return api({
                method: 'POST',
                url: apiTokenRequestUrl,
                data: "grant_type=refresh_token&refresh_token=" + refreshToken + "&client_id=" + clientid,
                headers: header
            });
        }
    };

    return tokens;
});
