ddd.factory('userService', function(tokenService, $cookies, $state) {
    var service = {
    };

    service.login = function(username, password) {
        var promise = tokenService.requestRefreshToken(username, encodeURIComponent(password));
        promise.then(function(response) {
            $cookies.put('shopId', response.shopid);
            $cookies.put('groupId', response.groupid);
        });
        return promise;
    };

    service.logout = function() {
        $cookies.remove('shopId');
        $cookies.remove('groupId');
        tokenService.setToken(null);
        tokenService.setRefreshToken(null);
        $state.go('login');
    };

    return service;
});
