ddd.provider('api', function() {

    var url = '';
    this.setUrl = function(apiUrl) {
        if (apiUrl && apiUrl.endsWith('/'))
            url = apiUrl.slice(0, apiUrl.length - 1);
        else url = apiUrl;
    };

    this.getUrl = function() {
        return url;
    };

    this.$get = function($http) {
        var that = this;

        function api(requestConfig) {
            var config = angular.extend({ apiRequest: true }, requestConfig);
            if (that.getUrl()) {
                var requestUrl = config.url.startsWith('/') ? config.url.slice(1) : config.url;
                config.url = that.getUrl() + "/" + requestUrl;
            }

            return $http(config);
        }

        createShortMethods('get', 'delete', 'head', 'jsonp');
        createShortMethodsWithData('post', 'put', 'patch');

        function createShortMethods(names) {
            angular.forEach(arguments, function(name) {
                api[name] = function(url, config) {
                    return api(angular.extend(config || {}, {
                        method: name,
                        url: url
                    }));
                };
            });
        }

        function createShortMethodsWithData(names) {
            angular.forEach(arguments, function(name) {
                api[name] = function(url, data, config) {
                    return api(angular.extend(config || {}, {
                        method: name,
                        url: url,
                        data: data,
                    }));
                };
            });
        }

        api.getUrl = that.getUrl;
        return api;

    };


});
