/**
 * Manager factory service for the on-screen keyboard. Inject this into your controller
 * if you want to show or hide the keyboard programatically. Registering inputs is primarily
 * done by the onscreen-keys directive, but can also be used from a controller if needed.
 * Register keypad is only done by the global keypad directive.
 * @param  {$timeout}                 angular's $timeout service
 * @return {keypadManager}            The keypadManager service for using in controllers
 */
ddd.factory('keypadManager', function($timeout, $parse, inputService, $window) {
    var keypadManager = {};
    var keypad;

    /**
     * Register the keypads API methods
     * @param  {object} _keypad The functions we need exposed from the keypad to register which input it should use, etc.
     */
    keypadManager.registerKeypad = function(_keypad) {
        keypad = _keypad;
    };

    /**
     * Register the active input. Primarily used by the onscreen-keys directive
     * @param  {DOMElement}     input       Input DOM element
     * @param  {function}       onEnter     Callback function for the OK button
     * @param  {boolean}        forceNumpad
     */
    keypadManager.registerInput = function(input, onEnter) {
        keypad.registerInput(input, onEnter);
    };

    /**
     * Show the onscreen keyboard for an input element.
     * If called with no parameters it will use the last registered input element as
     * receiver of keypad input. If an inputElement is passed this will be saved as
     * the last used input element.
     * @param  {DOMELement|null|undefined} inputElement input to use as receiver of keypad buttons presses
     * @param  {function} onEnter      Callback function for the OK button
     */
    keypadManager.show = function(inputElement, onEnter, forceNumpad) {
        keypad.showKeys(inputElement, onEnter, forceNumpad);

        /**
         * Hide inputs below keypad, to prevent caret-bleeding
         */
        if(inputElement != null) {
            var $keypadElement  = angular.element('.keyboard-wrap'),
                inputOffset     = inputElement[0].getBoundingClientRect();

            // Add a 400ms delay to ensure the the keypad height has been rendered properly
            setTimeout(function(){

                // Validate the input position relative to the keypad.
                if(($window.innerHeight - $keypadElement.outerHeight() <= inputOffset.top)) {
                    inputElement.addClass('below-keypad');
                }
            }, 400);
        }
    };

    /**
     * Hides the keypad
     */
    keypadManager.hide = function() {
        // hide disables global input, so we must only call hide if keypad was shown
        // otherwise we'll end up disabling the global input directive instead
        if (keypadManager.isVisible()) {
            keypad.hideKeys();
         }
    };

    /**
     * Returns keypad status
     */
    keypadManager.isVisible = function() {
        return keypad.isVisible();
    };



    /**
     * Get the start and end index of the current selection for an input element
     *
     * Chrome does not allow selection on number inputs so we try out different ways
     * if we have to change type to allow selection, we have to use $timeout and
     * allow the rest of the function to continue inside that timeout.
     *
     * slectionEnd wont be correct on the FIRST run unless we use the timeout
     * BUT! Only if the input is populated by any other source than the keypad
     * @param  {DOMElement} input           The input element to get selection on
     * @param  {function}   continueFunc    Callback which takes start and end
     *                                      index as parameters
     * @return {object}                     Has the selectionStart and selectionEnd properties
     */
    keypadManager.getSelection = inputService.getSelection;

    /**
     * Select range of text in an input element
     * @param  {Number}          start                zero-based index of start position of selection
     * @param  {Number}          end                  zero-based index of end position of selection
     * @param  {DOMElement|null} optionalUnboundInput An unbound input to use instead of the registered one
     */
    keypadManager.select = function(start, end, optionalUnboundInput) {
        inputService.select(start, end, optionalUnboundInput || keypad.getRegisteredInput());
    };
    /**
     * Select all the text in an input element
     * @param  {DOMElement|null} optionalUnboundInput An unbound input to use instead of the registered one
     */
    keypadManager.selectAll = function(optionalUnboundInput) {
        inputService.selectAll(optionalUnboundInput || keypad.getRegisteredInput());
    };

    return keypadManager;
});

//
/**
 * Directive for enabling focus on an input field to activate the on-screen keyboard.
 *
 * Usage:
 * <input onscreen-keys[="onEnterFunc()"] [onscreen-keys-force-numpad='boolean']> or
 * <input onscreen-keys [on-enter="onEnterFunc()"] (this works along side ddd-capture-global-input
 * which also uses on-enter)
 *
 * If you need the input to register it self as the current active input (for showing the keypad
 * programatically without knowing the element) you can use the onscreen-keys-activator='variable' attribute
 *
 * <input onscreen-keys[="onEnterFunc()"] [onscreen-keys-activator='variable']>
 */
ddd.directive('onscreenKeys', function(keypadManager, $parse, $document, $timeout, $rootScope) {
    return {
        restrict: 'A',
        link: function(scope, ele, attrs) {
            var forceNumpad = attrs.onscreenKeysForceNumpad ? attrs.onscreenKeysForceNumpad.toLowerCase() === 'true' : false;
            var autoSelect = attrs.onscreenKeysAutoSelect ? attrs.onscreenKeysAutoSelect.toLowerCase() === 'true' : false;
            var attrValue = attrs.onscreenKeys;
            ele.attr('readonly', 'readonly');
            if (!attrValue || attrValue === 'onscreen-keys') {
                attrValue = attrs.onEnter;
            }

            var hideKeypad = function() {
                $document[0].activeElement.blur();
            };

            var func = attrValue ? $parse(attrValue) : hideKeypad;
            var onEnter = !func ? null : function() {
                func(scope);
            };
            var hide = function() {
                if (!$rootScope.$$phase)
                    return scope.$apply(keypadManager.hide());
                keypadManager.hide();
            };

            keypadManager.registerInput(ele, onEnter);
            ele.on('focus', function(event, data) {
                if (!$rootScope.$$phase)
                    scope.$apply(keypadManager.show(ele, onEnter, forceNumpad));
                else keypadManager.show(ele, onEnter, forceNumpad);
                if (autoSelect)
                    keypadManager.selectAll();
            });

            ele.on('blur', hide);
            ele.on('$destroy', hide);

            var activator = attrs.onscreenKeysActivator;
            if (activator) {
                scope[activator] = function() {
                    keypadManager.show(ele, onEnter);
                };
            }

        }
    };

});

ddd.directive('keypad', function($state) {
    return {
        restrict: 'E',
        controller: function(inputService, $timeout) {
            var input;
            var onEnter;
            var lowercase = false;

            this.getRegisteredInput = function() {
                return input;
            };

            this.registerInput = function(inputElement, onEnterFunc) {
                input = inputElement;
                onEnter = onEnterFunc;
            };

            this.isExpanded = function() {
                var $keypadReflector = angular.element('.keypad__reflector');

                if (!input)
                    throw 'Keypad not paired with an input element';
                
                (input.attr('type') == 'password') ? $keypadReflector.addClass('is-protected') : $keypadReflector.removeClass('is-protected') ;

                return (input.attr('type') === 'text' || input.attr('type') === 'password');
            };

            this.clearInput = function() {
                input.val('');
                input.trigger('change');
                input.blur();
            };

            this.deleteChar = function() {
                if (input.val().length > 0) {
                    input.val(input.val().substr(0, input.val().length - 1));
                    input.trigger('change');
                }
            };

            this.enter = function() {
                var data = {
                    buttonName: input.val(),
                    stateName: $state.$current.name,
                    clientTime: new Date()
                };
                if (onEnter) {
                    onEnter();
                }
            };

            this.addCharacter = function(obj) {
                // convert decimal seperator function to string
                // the function binds on compile time so we need to pass in a function instead of a literal
                // since the localization happens after the directive is compiled
                if (typeof(obj) === 'function')
                    obj = obj();

                if (obj.length > 2) {
                    obj = String.fromCharCode(obj.substr(2, obj.length));
                }
                if (lowercase) obj = obj.toLowerCase();
                inputService.insertString(obj, input);
            };

            this.isLowerCase = function() {
                return lowercase;
            };

            this.toggleCase = function() {
                lowercase = !lowercase;
            };
        }
    };
});

ddd.directive('overlay', function(keypadManager, $document, $timeout, inputService, $locale) {
    return {
        restrict: 'A',
        scope: true,
        require: '^keypad',
        templateUrl: 'views/partials/key-wrapper',
        link: function(scope, ele, attrs, keypadCtrl) {

            scope.expandedKeys = false;
            scope.showKeys = false;
            var showKeysArguments = [];
            var focusSelfTriggered = false;

            ele.on('mousedown', function(event) {
                event.preventDefault();
            });

            var reflector;

            function toggleReflection(input) {
                if (typeof(reflector) === 'function') {
                    reflector();
                }

                if (!input) return;
                reflector = scope.$watch(function() {
                    return input.val() || input.attr('placeholder');
                }, function(value) {
                    scope.reflection = value;
                });
            }

            var keypad = {
                showKeys: function(inputElement, onEnter, forceNumpad) {
                    // when showKeys is triggered programatically from keypadManager, we'll use the focus event
                    // to put the input in focus, which in return will end back here in showKeys. The only catch is that
                    // in the second trip to showKeys we will have lost the original function arguments but the one from the
                    // onscreen-keys directive. Therefore we'll keep track on whether this invocation is due to our own focus roundtrip
                    // and then use the arguments from the previous invocation.
                    if (!focusSelfTriggered)
                        showKeysArguments = arguments;
                    else focusSelfTriggered = false;

                    var activeElementIsRegisteredInput = $document[0].activeElement === keypadCtrl.getRegisteredInput()[0];
                    if (scope.showKeys && activeElementIsRegisteredInput) return;

                    if (showKeysArguments[0] /* inputElement */) {
                        keypadCtrl.registerInput(showKeysArguments[0] /* inputElement */, showKeysArguments[1] /* onEnter */);
                    }

                    if (!activeElementIsRegisteredInput) {
                        // let the next function invocation know that we triggered this ourselves
                        focusSelfTriggered = true;
                        keypadCtrl.getRegisteredInput().focus();
                        return;
                    }

                    toggleReflection(keypadCtrl.getRegisteredInput());
                    scope.expandedKeys = !(showKeysArguments[2] /* forceNumpad */ || !keypadCtrl.isExpanded());
                    scope.showKeys = true;
                    $timeout(function() {
                        inputService.enableGlobalCapture(keypadCtrl.getRegisteredInput(), showKeysArguments[1] /* onEnter */);
                    });
                },
                hideKeys: function() {
                    toggleReflection();
                    scope.showKeys = false;
                    var input = keypadCtrl.getRegisteredInput() || {};
                    inputService.disableGlobalCapture(input);
                    $timeout(function() {
                        var activeElementIsRegisteredInput = $document[0].activeElement === input[0];
                        if (activeElementIsRegisteredInput) {
                            $document[0].activeElement.blur();
                        }
                    });

                    // Reset any input field, that has the below-keypad class.
                    angular.element('.below-keypad').length && angular.element('.below-keypad').removeClass('below-keypad');
                },
                isVisible: function() {
                    return scope.showKeys;
                },
                registerInput: keypadCtrl.registerInput,
                getRegisteredInput: keypadCtrl.getRegisteredInput
            };
            keypadManager.registerKeypad(keypad);

            scope.toggleExpandedKeys = function() {
                scope.expandedKeys = !scope.expandedKeys;
            };

            scope.hideKeyboard = function() {
                return keypad.hideKeys();
            };

            scope.decimalSeparator = function() {
                return $locale.NUMBER_FORMATS.DECIMAL_SEP;
            };

            scope.enter = keypadCtrl.enter;
            scope.toggleCase = keypadCtrl.toggleCase;
            scope.keypadArray = keypadCtrl.addCharacter;
            scope.deleteChar = keypadCtrl.deleteChar;
            scope.clearInput = keypadCtrl.clearInput;
            scope.isLowerCase = keypadCtrl.isLowerCase;
            scope.keysSelectAll = keypadCtrl.keysSelectAll;
        }
    };
});

ddd.directive('inview', function($locale) {
    return {
        restrict: 'A',
        require: 'keypad',
        scope: {
            target: '=',
            onEnter: '&'
        },
        templateUrl: 'views/partials/key-pad-inview',
        link: function(scope, ele, attrs, keypadCtrl) {
            var input = angular.element(scope.target);
            keypadCtrl.registerInput(input, scope.onEnter);
            scope.decimalSeparator = function() {
                return $locale.NUMBER_FORMATS.DECIMAL_SEP;
            };
            scope.enter = keypadCtrl.enter;
            scope.toggleCase = keypadCtrl.toggleCase;
            scope.keypadArray = keypadCtrl.addCharacter;
            scope.deleteChar = keypadCtrl.deleteChar;
            scope.clearInput = keypadCtrl.clearInput;
            scope.getDotOrComma = keypadCtrl.getDotOrComma;
        }
    };
});
