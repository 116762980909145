ddd.controller('DashboardCtrl', function($scope, api, $log, userService, $window, $location, notificationService, $cookies) {
    $scope.modules = [];
    var releaseType = "DDD_RELEASE_TYPE";
    var effects = ['translatebottomup', 'translatetopdown', 'flipped', 'vertical'];

    api.get('api/dashboard/modules').then(resolveModuleUrls);

    notificationService.showJsonMessage($scope.$stateParams.message);

    function resolveModuleUrls(moduleResponse) {
        var parts = $location.host().split('.');
        var sndleveldomain = parts.slice(-2).join('.');
        var domain = sndleveldomain !== 'localhost' ? sndleveldomain : '';
        api.post('api/dashboard/modules/full', {
            hostname: domain,
            release: releaseType,
            modules: moduleResponse.data
        }).then(function(response) {
            $scope.modules = response.data;
        });
    }

    $scope.goToModule = function(module, shopId) {
        // the module knows which type of relation it uses, and we need to set the cookie key
        // for that type of relation. e.g. "contextShopId" or "contextLoyaltyClubId".
        var key = 'context' + module.relationName + "Id";
        var protocol = $location.protocol();

        // The POS module is a special case and we dont want to change the shopId context when
        // changing for the other modules. We only chang the shopId context for the POS when
        // explicitly clicking a new shopId on the POS module.
        // For legacy reasons the POS uses the generic key 'shopId' and modules use 'contextShopId'.
        if (module.moduleName === 'pos') {
            key = 'shopId';
            // POS cannot run https yet
            // TODO: Remove when POS runs https
            protocol = 'http';
        }

        // the price and promotion engine is currently only in http, thus cannot be consumed from a https site
        if (module.moduleName === 'pricepromotion') {
            protocol = 'http';
        }

        // all other modules change shopId together. So going to Admin 996001 and then changing
        // directly to another module without going through the portal will make that module also
        // be in the context of 996001
        $cookies.put(key, shopId);

        $window.location = protocol + '://' + module.url;
    };

    $scope.getTileStyle = function(index) {
        return index % 2 === 0 ? 'tile-wide' : 'tile-narrow';
    };

    $scope.getEffect = function(index) {
        return effects[index % 4];
    };

    $scope.logout = function() {
        userService.logout();
    };

});
