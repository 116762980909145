ddd.directive('fliptile', function($interval, $window, $parse) {
	return {
		restrict: "E",
        link: function(scope, ele, attr){
            ele.addClass("fliptile");
            //console.log(attr);
            //Append basic styles to all elements
			function getEffect(element) {
				var effectFunc = $parse(angular.element(element).attr('effect'));
				return effectFunc(scope);
			}

			angular.forEach(ele.children(), function(child){
                angular.element(child).addClass("tile");
                angular.element(child).addClass("inactive");
				angular.element(child).addClass(getEffect(child));
                console.log(getEffect(child));
            });

            //Make sure first element is active
            angular.element(ele.children()[0]).addClass("active");
            angular.element(ele.children()[0]).removeClass("inactive");

            // Init counter
            var counter = 0;
            var curEffect = getEffect(ele.children()[counter]);
            var nextEffect = getEffect((ele.children()[(counter + 1) % ele.children().length]));
            var preElem = angular.element(ele.children()[ele.children().length - 1]);

            // Flip tiles on interval
            var flipper = $interval(function(){
                curEffect = getEffect(ele.children()[counter]);
                nextEffect = getEffect(ele.children()[(counter + 1) % ele.children().length]);

                // Need to reset the previous element
                preElem.removeClass(curEffect);
                preElem.addClass(getEffect(preElem));
                preElem.addClass('disabled');
                preElem = angular.element(ele.children()[counter]);

                angular.element(ele.children()[counter]).removeClass(curEffect);
                angular.element(ele.children()[counter]).addClass(nextEffect);
                angular.element(ele.children()[counter]).removeClass("active");
                angular.element(ele.children()[counter]).removeClass("active");
                angular.element(ele.children()[counter]).removeClass("disabled");
                angular.element(ele.children()[counter]).addClass("inactive");

                // increment counter
                counter = ++counter % ele.children().length;

                angular.element(ele.children()[counter]).removeClass(curEffect);
                angular.element(ele.children()[counter]).addClass(nextEffect);
                angular.element(ele.children()[counter]).removeClass("inactive");
                angular.element(ele.children()[counter]).removeClass("disabled");
                angular.element(ele.children()[counter]).addClass("active");

            }, $window.Math.floor($window.Math.random() * 11000) +  7000);

            ele.on("$destroy", function(){
                $interval.cancel(flipper);
            });
        }
	};
});
