// require dependencies
global.jQuery = require("./..\\..\\bower_components\\jquery\\dist\\jquery.js");
require("./..\\..\\bower_components\\bootstrap\\dist\\js\\bootstrap.js");
require("./..\\..\\bower_components\\angular\\index.js");
require("./..\\..\\bower_components\\ui-router\\release\\angular-ui-router.js");
require("./..\\..\\bower_components\\angular-cookies\\angular-cookies.js");
require("./..\\..\\bower_components\\angular-loading-bar\\build\\loading-bar.js");
require("./..\\..\\bower_components\\angular-strap\\dist\\angular-strap.js")
require("./..\\..\\bower_components\\angular-strap\\dist\\angular-strap.tpl.js")
require('angular-translate');
require('angular-translate-loader-static-files');
require('angular-dynamic-locale');
require("./..\\..\\bower_components\\AngularJS-Toaster\\toaster.js");

var bulk = require('bulk-require');

// require the app
bulk(__dirname, '**/*.js');
