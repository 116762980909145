ddd.service('Aside', function($aside) {
    var defaultAsideTpl = '/views/shared/aside';
    var defaultAside = {
        content: ' '
    };

    var theScope;

    function Aside(scope, options) {
        theScope = scope;
        
        var defaultOptions = {
            scope: scope,
            templateUrl: defaultAsideTpl,
            title: defaultAside.title,
            content: defaultAside.content,
            animation: 'am-slide-right',
            placement: 'right',
            show: false
        };

        if (options && !angular.isObject(options)) {
            angular.extend(defaultOptions, {
                placement: options,
                animation: 'am-slide-' + options
            });
        } else angular.extend(defaultOptions, options);

        var theAside = $aside(defaultOptions);

        theAside.$promise.then(function() {

        });

        this.isShown = function() {
            return theAside.$isShown;
        };

        this.hide = function() {
            theAside.hide();
        };

        this.show = function() {
            theAside.show();
        };
    }

    return Aside;
});
