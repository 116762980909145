ddd.controller('LoginCtrl', function($scope, userService, $translate, notificationService, toaster, keypadManager) {
    $scope.email = '';
    $scope.password = '';

    notificationService.showJsonMessage($scope.$stateParams.message);

    $scope.i18n = {
        portalLogin: '',
        username: '',
        password: '',
    };

    $translate([
        'auth/portalLogin',
        'auth/username',
        'auth/password',
    ])
    .then(function(translations) {
        $scope.i18n.portalLogin = translations['auth/portalLogin'];
        $scope.i18n.username = translations['auth/username'];
        $scope.i18n.password = translations['auth/password'];
    });

    $scope.validate = function() {
        userService.login($scope.username, $scope.password)
            .then(function() {
                $scope.$state.go('root.dashboard');
            },
            function() {
                invalidLogin();
            });

        function invalidLogin() {
            toaster.pop('error', 'Incorrect login', 'Sorry, the email and/or password appears to be incorrect. Please try again.');
        }
    };

});
